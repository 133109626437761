// extracted by mini-css-extract-plugin
export var backgroundAnimation = "people-presentation-module--backgroundAnimation--4bc15";
export var descriptionText = "people-presentation-module--description-text--93154";
export var grid = "people-presentation-module--grid--5ce0e";
export var icon = "people-presentation-module--icon--7afce";
export var image = "people-presentation-module--image--30b05";
export var item = "people-presentation-module--item--2a495";
export var nameText = "people-presentation-module--name-text--8fd56";
export var next = "people-presentation-module--next--032a1";
export var overwrite = "people-presentation-module--overwrite--85c59";
export var prev = "people-presentation-module--prev--7023a";
export var sectionContainer = "people-presentation-module--section-container--ed299";
export var slider = "people-presentation-module--slider--ff9b4";
export var specText = "people-presentation-module--spec-text--241a9";
export var subtitleText = "people-presentation-module--subtitle-text--8bb2e";
export var titleText = "people-presentation-module--title-text--4a1d9";