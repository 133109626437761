import React from 'react';
import { Image } from '@alterpage/gatsby-plugin-image';

import {
    overwrite,
    sectionContainer,
    grid,
    titleText,
    subtitleText,
    descriptionText,
    slider,
    item,
    image,
    nameText,
    specText,
    next,
    prev,
    icon,
} from './people-presentation.module.scss';

import { ISection } from '../../models/section.model';
import { IPerson } from '../../models/person.model';

import Section from '../hoc/section';
import Slider from '../hoc/slider';
import IconButton from '../atoms/icon-button';

import ArrowLeftIcon from '../../assets/images/svg/arrow-left.svg';
import ArrowRightIcon from '../../assets/images/svg/arrow-right.svg';
import { useSwiperLoading } from '../../hooks/use-swiper-loading';

interface IPeopleSection extends ISection {
    items: {
        people: IPerson[];
    };
}

interface IPresentationProps {
    className?: string;
    section: IPeopleSection;
    TitleTag?: React.ElementType;
}

const PeoplePresentation: React.FC<IPresentationProps> = ({
    className = '',
    TitleTag,
    section,
}) => {
    const {
        items: { people },
        sectionId,
    } = section;
    const { loading, navigationNextRef, navigationPrevRef } = useSwiperLoading();
    const [title, subtitle, description] = section.content.texts;

    return (
        <Section
            className={`${className} ${sectionContainer} ${overwrite}`}
            TitleTag={TitleTag}
            title={title}
            subtitle={subtitle}
            description={description}
            classes={{
                container: grid,
                title: titleText,
                subtitle: subtitleText,
                description: descriptionText,
            }}
            roundedSection={true}
            sectionId={sectionId}
        >
            <IconButton className={prev} innerRef={navigationPrevRef} title={'prev'}>
                <ArrowLeftIcon className={icon} />
            </IconButton>
            <IconButton className={next} innerRef={navigationNextRef} title={'next'}>
                <ArrowRightIcon className={icon} />
            </IconButton>
            <Slider
                loading={loading}
                className={slider}
                spaceBetween={0}
                rewind={true}
                navigation={{
                    prevEl: navigationPrevRef.current,
                    nextEl: navigationNextRef.current,
                }}
                breakpoints={{
                    0: {
                        slidesPerView: 1,
                    },
                    800: {
                        slidesPerView: 2,
                    },
                    1024: {
                        slidesPerView: 3,
                    },
                }}
            >
                {people.map((person: IPerson, index) => {
                    return (
                        <div className={item} key={`person-${index}`}>
                            <Image className={image} media={person.media} />
                            <p className={nameText}>
                                {person.title && person.title + ' '}
                                {person.firstName} {person.lastName}
                            </p>
                            <p className={specText}>{person.position}</p>
                        </div>
                    );
                })}
            </Slider>
        </Section>
    );
};

export default PeoplePresentation;
